import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

const StyledMembershipPlans = styled.div`
    color: ${colors.white};
    background: ${colors.blueLight};
    margin: 4.8em 0em 4em;
    padding: 3em 1em 2em;
    .iphone {
        margin: 2em 0em;
    }
    h3 {
        font-style: italic;
        margin: 0em 0em;
        font-family: 'Bodini MT';
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
    }
    h4 {
        font-size: 36px;
        line-height: 44px;
    }
    .sub {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
    }
    p {
        margin: 1.25em 0em;
        color: ${colors.white};
    }
    ul {
        padding-left: 0em;
        li {
            margin: 1em 0em;
            list-style-type: none;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            span {
                background: ${colors.white};
            }
            display: flex;
            align-items: center;
            padding-right: 1em;
            div {
                margin-right: 1em;
                color: ${colors.blueLight};
                background: ${colors.white};
                border-radius: 400px;
                padding: 0.25em 0.5em;
            }
        }
    }
    @media ${device.tablet} {
        margin: 12.8em 0em 4em;
        .list-container {
            position: relative;
            left: 1em;
        }
        .flex-container {
            display: flex;
            padding-top: 5em;
        }
        .iphone {
            display: none;
        }
    }
    @media ${device.laptop} {
        min-height: 780px;
        height: 85vh;
        .list-container {
            position: relative;
            left: 3em;
        }
        .flex-container {
            max-width: 1230px;
            margin: 0 auto;
            justify-content: space-around;
            padding-bottom: 0em;
        }

        .text-container {
            h3 {
                padding-right: 2em;
            }
            padding: 0em 0em;
            max-width: 400px;
        }
        .list-container {
            max-width: 400px;
        }
        .iphone {
            position: relative;
            bottom: 2em;
            width: 350px;
            display: block;
        }
    }
    @media ${device.laptop} {
        .flex-container {
            max-width: 1250px;
        }
    }
`;

export const MembershipPlans: React.FC = () => {
    return (
        <StyledMembershipPlans>
            <div className="flex-container">
                <div className="text-container">
                    <h3>Bloom La Vie Membership Plan</h3>
                    <span className="sub">12 Month Plan to help you live a healthy life.</span>
                    <p>
                        Here at Bloom La Vie Health, our pricing system is very straightforward –
                        just like it should be. We cut out the insurance middle-man, so we could
                        focus on providing care.{' '}
                    </p>
                    <Link to="/plans/bloom-membership/">
                        <Button color="white">
                            {' '}
                            <span>Membership Plan & Pricing</span>{' '}
                            <StaticImage
                                src="../images/svgs/arrow-right-white.svg"
                                placeholder="none"
                                quality={100}
                                alt="white arrow point right"
                            />
                        </Button>
                    </Link>
                </div>
                <StaticImage
                    className="iphone"
                    src="../images/iphone.png"
                    placeholder="none"
                    quality={100}
                    alt="iphone with girl on screen"
                />
                <div className="list-container">
                    <h4>Benefits:</h4>
                    <ul>
                        <li>
                            <div style={{ padding: '.25em .65em' }}>1</div>12 months of care
                        </li>
                        <li>
                            <div>2</div>No limit to office or video visits during regular business
                            hours (subject to availability)
                        </li>
                        <li>
                            <div>3</div>Communicate directly with the doctor and care team by email
                            and text through secure patient messaging tools
                        </li>
                        <li>
                            <div>4</div>Nurse-only visits included without visit fees
                        </li>
                        <li>
                            <div>5</div>No additional fees for lab draws or specimen collections
                        </li>
                        <li>
                            <div>6</div>Includes routine annual basic screening CBC, CMP, Lipid
                            profile, A1c (for adult memberships only)
                        </li>
                        <li>
                            <div>7</div>Includes in-office UA dip, fecal blood test and glucose
                            check
                        </li>
                        <li>
                            <div>8</div>Discounts on in-office procedures
                        </li>
                    </ul>
                </div>
            </div>
        </StyledMembershipPlans>
    );
};
